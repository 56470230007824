import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Slug from '../../components/slug/slug';
import Card from '../../components/card/card';
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';

export default class MagazineArchive extends Component {
  constructor(props) {
    super(props);

    this.totalMagazines = get(props, 'magazines.length', 0);
    this.state = {
      limit: props.limit
    };

    this.showAllMagazines = this.showAllMagazines.bind(this);
  }

  showAllMagazines() {
    this.setState({
      limit: this.totalMagazines
    });
  }

  render() {
    const { config, title, description, eventCategory } = this.props;

    const magazines = get(this.props, 'magazines', []);

    return (
      <div className="magazine-archive">
        <Slug slugName={title} linkTo="https://www.fastcompany.com/magazine" />
        <h3 className="page-description">{description}</h3>
        <div className="row row--magazine">
          {magazines.slice(0, this.state.limit).map(issue => {
            if (!issue) {
              return null;
            }

            return (
              <Card
                config={config}
                key={issue.issue}
                type="magazine"
                metadata={issue}
                cardEvent={() => {
                  if (eventCategory) {
                    trackGAEvent({
                      eventCategory,
                      eventAction: 'Magazine',
                      eventLabel: `Issue ${issue.issue}`
                    });
                  }
                }}
              />
            );
          })}
        </div>
        {this.state.limit !== this.totalMagazines ? (
          <div className="magazine-archive__see-more">
            <button
              className="magazine-archive__button"
              onClick={this.showAllMagazines}
            >
              See More Magazine Issues
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

MagazineArchive.defaultProps = {
  title: 'Fast Company Magazine',
  description: 'Browse Our Most Current Issues',
  eventCategory: '',
  limit: 4
};

MagazineArchive.propTypes = {
  config: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  eventCategory: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  magazines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  limit: PropTypes.number
};
